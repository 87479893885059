import { dataState, statusState, filtersState } from '../commonState';
import {
	fetch_items,
	multipurpose_response,
	save_data,
	delete_item
} from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial orders state
const scopedState = {
	// postsSomeData: {}
	filters: {
		...filtersState.filters,
		status: null,
		daterange: []
	},
	fetchedMeta: {}
};

const state = { ...dataState, ...statusState, ...scopedState };
const mutations = {
	...dataMutations,
	...statusMutations,

	SET_LIST_ITEM: (state, { idx, item }) => {
		if (state.itemsList[idx]) {
			state.itemsList[idx] = item;
		}
	}
};
const getters = {};

// actions
const actions = {
	fetch_orders(storeArgs, payload = {}) {
		// const newPayload = {...payload, stateProp:'itemsList'}
		return fetch_items(storeArgs, '/orders', payload);
	},

	fetch_order(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			stateProp: 'itemData',
			alternateResponseProp: 'data'
		};
		return multipurpose_response(
			storeArgs,
			`/orders/${extendedPayload.itemId}`,
			extendedPayload
		);
	},

	save_order(storeArgs, payload) {
		const extendedPayload = { ...payload, stateProp: 'itemData' };
		return save_data(storeArgs, `/orders`, extendedPayload);
	},

	update_order_status(storeArgs, payload) {
		const extendedPayload = {
			...payload,
			// stateProp: 'itemData',
			method: 'PUT',
			notSetToStore: true,
			withoutDataCheck: true,
			notNotify: true
		};
		return multipurpose_response(
			storeArgs,
			`/orders/status/${extendedPayload.itemId}`,
			extendedPayload
		);
	},

	delete_order(storeArgs, payload) {
		return delete_item(storeArgs, `/orders`, payload);
	},

	set_orders({ commit }, items = []) {
		const payload = { stateProp: 'itemsList', value: items };
		commit('SET_STATE', payload);
	},

	set_order({ commit }, item = null) {
		const payload = { stateProp: 'itemData', value: item };
		commit('SET_STATE', payload);
	},

	set_orders_filters({ commit }, filters) {
		const payload = { stateProp: 'filters', value: filters };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
