import { dataState, statusState, filtersState } from '../commonState';
import { multipurpose_response } from '../commonActions';
import { dataMutations, statusMutations } from '../commonMutations';

// initial orders state
const scopedState = {
	citiesList: [],
	citiesLoading: false,
	warehousesList: [],
	warehousesLoading: false
};

const state = { ...dataState, ...statusState, ...filtersState, ...scopedState };
const mutations = { ...dataMutations, ...statusMutations };
const getters = {};

// actions
const actions = {
	fetch_cities(storeArgs, payload = {}) {
		// console.log(storeArgs.rootState.auth.cookie_hash)
		const extendedPayload = {
			...payload,
			stateProp: 'citiesList',
			loadingProp: 'citiesLoading',
			// alternateResponseProp: 'data',
			prepareData: 'prepareCitiesList',
			notNotify: true
		};
		return multipurpose_response(storeArgs, `/location/np/cities`, extendedPayload);
	},

	fetch_warehouses(storeArgs, payload = {}) {
		// console.log(storeArgs.rootState.auth.cookie_hash)
		const extendedPayload = {
			...payload,
			stateProp: 'warehousesList',
			loadingProp: 'warehousesLoading',
			// alternateResponseProp: 'data',
			notNotify: true
		};
		return multipurpose_response(
			storeArgs,
			`/location/np/warehouses`,
			extendedPayload
		);
	},

	set_cities({ commit }, items = []) {
		const payload = { stateProp: 'citiesList', value: items };
		commit('SET_STATE', payload);
	},
	set_warehouses({ commit }, items = []) {
		const payload = { stateProp: 'warehousesList', value: items };
		commit('SET_STATE', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
