// import { findItemBy } from '@/helpers';
import uniqid from 'uniqid';

const createFormItemMixin = {
	computed: {
		validateSubItems: () => true
	},

	methods: {
		setupFormSubItemsList(dataList, uniqidPrefix) {
			const itemsList = [];
			if (dataList) {
				for (const item of dataList) {
					itemsList.push({ id: uniqid(`${uniqidPrefix}-`), ...item });
				}
			}
			return itemsList;
		},

		addFormItem(prop, uniqPrefix) {
			let newItem = {
				id: uniqid(uniqPrefix),
				new: true
			};
			this[prop].push(newItem);
		},

		removeFormItem(id, listName) {
			// console.log(id, listName)
			this[listName] = this[listName].filter(o => o.id !== id);
		}
	}
};

export default createFormItemMixin;
