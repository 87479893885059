import { prepareRangeParams } from '@/helpers';

const itemsDataMixin = {
	data: () => ({
		preventFetch: false,

		// itemData: null,
		// itemsList: [],
		// itemsLoading: false,
		// meta: {}
	}),

	computed: {
		navbarSettings: () => ({
			showCreateButton: true,
			showDeleteButton: true,
			showFilter: true
		})
	},
	methods: {
		fetchItems(filters) {
			let payload = { params: { ...filters } };

			if (filters.daterange && filters.daterange.length) {
				payload.params = {
					...payload.params,
					...prepareRangeParams(filters.daterange)
				};
				delete payload.params.daterange;
			}

			this.fetch_items(payload);
		},

		editItem({ id }) {
			const route = `${this.$route.fullPath}/${id}`;
			this.$router.push(route);
		},

		handleDeleteItems() {
			const ids = this.$refs.ItemsTableContainer.selectedIds;

			if (ids.length) {
				this.$confirm({
					title: 'Warning',
					message: `This will permanently delete selected ${this.itemsName.mult}. Continue?`,
					confirmButtonText: 'Delete',
					showCancelButton: true,
					cancelButtonText: 'Cancel',
					iconClass: 'icomoon icon-warning',
					type: 'warning'
				})
					.then(() => {
						this.deleteItem({ ids: ids });
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: 'Delete canceled'
						});
					});
			} else {
				this.$message({
					type: 'info',
					message: 'Select items first'
				});
			}
		},

		deleteItem(data) {
			// console.log(data)
			this.delete_item({ data: data }).then(() => {
				this.fetchItems({ ...this.filters, ...this.globalFilters });
			});
		},

		setFilters(filters) {
			const newFilters = { ...this.filters, ...filters };
			
			if (this.filters.page == newFilters.page) {
				newFilters.page = 1;
			}

			this.set_filters(newFilters);
		}
	},

	watch: {
		filters(filters) {
			if (this.preventFetch) {
				this.preventFetch = false;
			} else {
				this.fetchItems({
					...this.globalFilters,
					...filters,
					// ...this.preventedFilters
				});
			}
		},
		globalFilters(globalFilters) {
			this.preventFetch = true;
			this.setFilters({ page: 1 });

			this.fetchItems({
				...this.filters,
				...globalFilters,
			});
		}
	},

	created() {
		this.setup_navbar(this.navbarSettings);
		this.fetchItems({ ...this.filters, ...this.globalFilters });
	},

	beforeDestroy() {
		this.set_items([]);
		this.setup_navbar({});
		this.setFilters({ page: 1 });
	}
};

export default itemsDataMixin;
