<template>
	<div>
		<VueElementLoading
			:active="authIsLoading || overlayData.show"
			:text="overlayData.text"
			:is-full-screen="true"
		/>

		<div
			v-if="!authIsLoading && isAuthenticated && !isAuthChecking"
			:class="['dashboard-wrapper wrapper', { 'sidebar-mini': isSidebarCollapse }]"
		>
			<CoverOverlay :active="overlayOptions.show" :z="overlayOptions.zIndex" />

			<router-view
				name="sidebar"
				:is-collapse="isSidebarCollapse"
				:is-show="isSidebarShow"
				:current-path="currentPath"
				@event="handleEvent"
			/>

			<div class="main-panel">
				<router-view
					name="navbar"
					:current-path="currentPath"
					:navbar-settings="navbarSettings"
					@event="handleEvent"
				/>

				<div class="dashboard-content-wrapper">
					<div class="dashboard-content-container">
						<transition name="component-scale" mode="out-in">
							<router-view ref="viewContent" />
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import CoverOverlay from '@/components/CoverOverlay.vue';
import { eventHandler, navigation } from '@/mixins';
// import { USER_TYPES } from '@/constants/global';
// import { getTokenFromUrl } from '@/services/api/api_helpers';

export default {
	name: 'DashboardLayout',
	components: {
		// NavigationButtons: () => import('./components/NavigationButtons.vue'),

		// Sidebar: () => import('./components/Sidebar/Sidebar.vue'),
		// TopNavbar: () => import('./components/TopNavbar.vue'),
		CoverOverlay
	},
	mixins: [eventHandler, navigation],
	data() {
		return {
			overlayOptions: {
				show: false,
				zIndex: 5000
			},
			// isSidebarCollapse: false,
			isSidebarShow: false,
			// currentPath: '',
			isAuthChecking: true
		};
	},

	computed: {
		...mapState({
			navbarSettings: state => state.global.navbarSettings,
			overlayData: state => state.global.overlay,
			authIsLoading: state => state.auth.isLoading,
			isAuthenticated: state => state.auth.isAuthenticated,
			authUser: state => state.auth.authUser,
			isSidebarCollapse: state => state.global.isSidebarCollapse
		}),

		currentPath() {
			return this.$route.fullPath;
		}
	},

	watch: {
		/*$route(to) {
			console.log('1', to)
			// this.currentPath = to.fullPath;
		},*/
		currentPath(path) {
			if (path == '/') {
				this.$router.push('/orders');
			}
			// console.log('2', path)
		}
	},

	beforeMount() {
		// console.log('DashboardLayout.vue')

		this.$store.dispatch('auth/get_auth_user').then(() => {
			this.isAuthChecking = false;
			// this.currentPath = this.$router.currentRoute.fullPath;
			if (this.currentPath === '/') {
				let path = '/orders';
				/*if (this.authUser.type === USER_TYPES.INTERNAL) {
					path = '/dashboard';
				}*/
				this.$router.push(path);
			}
		});
	},

	methods: {
		signIn(token) {
			this.$store.dispatch('auth/get_auth_user', token).then(() => {
				// this.isAuthChecking = false;
				this.$router.push('/dashboard');
			});
		},

		minimizeSidebar() {
			this.$store.dispatch('minimizeSidebar');
			// this.isSidebarCollapse = !this.isSidebarCollapse;
		},

		toggleSidebar() {
			this.isSidebarShow = !this.isSidebarShow;
			this.overlayOptions = {
				show: this.isSidebarShow,
				zIndex: 800
			};
		},

		createItem() {
			if (this.$refs.viewContent.handleCreateItem) {
				this.$refs['viewContent'].handleCreateItem();
			}
		},

		saveItem() {
			if (this.$refs.viewContent.handleSaveItem) {
				this.$refs['viewContent'].handleSaveItem();
			}
		},
		cleanForm() {
			if (this.$refs.viewContent.handleCleanForm) {
				this.$refs['viewContent'].handleCleanForm();
			}
		},
		deleteItems() {
			if (this.$refs.viewContent.handleDeleteItems) {
				this.$refs['viewContent'].handleDeleteItems();
			}
		}
	}
};
</script>
