import {
	updateFormData,
	prepareSubmitData,
	prepareUploadData,
	// cleanString,
	setObjectVal
} from '@/helpers';
// import { isEmptyString } from '@/utils/validate';

const itemFormMixin = {
	data() {
		return {
			isMobile: false,
			itemId: null,
			isInitialSetup: true
		};
	},

	methods: {
		/*setupFormDataByUserType(authUser, props) {
			for (let i = 0; i < props.length; i++) {
				const prop = props[i];
				if (authUser[prop]) {
					this.formData[prop] = authUser[prop];
				}
			}
		},*/

		cleanFormDataProps(props) {
			for (const propItem of props) {
				setObjectVal(this, propItem.accessor, propItem.val);
			}
		},

		setupForm(itemData, formData) {
			// console.log(updateFormData(itemData, formData))
			return updateFormData(itemData, formData);
		},

		setupPage(item, options = {}) {
			const prop = options.formDataName || 'formData';
			const idProp = options.idPropName || this.idPropName || 'id';

			if (item) {
				this.itemId = item[idProp];
				this[prop] = this.setupForm(item, this[prop]);
			} else {
				this.itemId = null;
			}


			if (this.new_item_type) {
				this.formData.type = this.new_item_type;
			}

			if (this.localSetupPage) this.localSetupPage(item);
			
			setTimeout(() => {
				this.isInitialSetup = false;
			}, 0);
			// console.log(idProp);
		},

		validateForm(options = {}) {
			const ref = options.activeFormName || 'itemForm';

			this.$refs[ref].validate(valid => {
				if (valid) {
					if (this.validateSubItems) {
						this.handleSaveItem();
					} else {
						this.submitForm(options);
					}
				} else {
					this.$notify({
						type: 'warning',
						title: 'Форма не готова',
						message: `Проверте все ли обязательные поля заполненены`
					});
					return false;
				}
			});
		},

		submitForm(options = {}) {
			let { formDataName, injectToBody } = options;
			injectToBody = injectToBody || {};
			// const formDataName = formDataName || 'formData';
			// const idProp = options.idPropName || this.idPropName || 'id';
			let data = {
				id: this.itemId,
				...this[formDataName || 'formData'],
				...prepareUploadData(this),
				...injectToBody
			};

			/*if (this.translitSettings) {
				const { formFields } = this.translitSettings;

				for (const field of formFields) {
					const { prop, sourceProp, ifIsEmpty } = field;
					// console.log(prop, sourceProp)
					if (ifIsEmpty) {
						if (isEmptyString(data[prop] || '')) {
							data[prop] = this.$cyrillic_to_translit(data[sourceProp], '-');
						}
					} else {
						data[prop] = this.$cyrillic_to_translit(data[sourceProp], '-');
					}

					data[prop] = cleanString(data[prop]);
				}
			}*/

			if (this.localPrepareSubmitData) {
				data = this.localPrepareSubmitData(data);
			}
			// const payload = { ...prepareSubmitData(data) }
			// console.log(payload);
			/*if (data) {
				console.log(data.alias)
				return;
			}*/

			this.$emit('submit', { ...prepareSubmitData(data) });
		}
	},

	watch: {
		itemData(data) {
			this.setupPage(data);
		}
	},

	created() {	
		this.setupPage(this.itemData);
	}
};

export default itemFormMixin;
