import { updateFormData, prepareSubmitData, prepareUploadData } from '@/helpers';

const subItemFormMixin = {
	props: {
		itemIndex: Number,
		// clearable: Boolean,
		// query: String,
		itemData: {
			type: Object,
			required: true
		},

		required: Boolean,
		copyItem: Boolean
	},

	data() {
		return {
			new: false,
			itemId: null,
			isValidMain: false
		};
	},

	methods: {
		setupForm(itemData, formData) {
			let additionalRules = {};
			if (this.localSetupFormActions) {
				this.localSetupFormActions(itemData, additionalRules);
			}
			return updateFormData(itemData, formData, additionalRules);
		},

		setupPage(item) {
			this.new = item.new || false;
			this.itemId = item.id;
			this.formData = this.setupForm(item, this.formData);

			if (this.localSetupPageActions) {
				this.localSetupPageActions(item);
			}
		},

		validateItemForm() {
			this.$refs['itemForm'].validate(valid => {
				this.isValidMain = valid;
				if (this.validateSubItems) {
					this.handleValidateRefsItems();
				} else {
					this.submitItemForm();
				}
			});
		},

		submitItemForm(options = {}) {
			let { injectToBody } = options;
			injectToBody = injectToBody || {};

			let payload = {
				data: {
					...prepareSubmitData(this.formData),
					...prepareUploadData(this),
					...injectToBody
				},
				formDataProp: this.formDataProp || this.targetPropName,
				isValid: this.isValidMain
			};

			if (this.localPreSubmitActions) {
				payload = this.localPreSubmitActions(payload);
			}

			if (this.copyItem || this.new) {
				delete payload.data.id;
			}

			// console.log(payload);

			this.$emit('ready', payload);
		},

		removeItem() {
			this.$emit('onRemove', this.itemId);
		}
	},

	created() {
		this.setupPage(this.itemData);
	}
};

export default subItemFormMixin;
