const itemPageMixin = {
	computed: {
		navbarSettings: () => ({
			showSaveButton: true,
			showCloseButton: true
		}),

		pageTitle() {
			const itemName = this.itemsName.one;
			if (this.itemData) {
				// return `${itemName}: ${this.companyItem.name}`;
				return `${itemName}`;
			}
			return `Новый(ая) ${itemName}`;
		}
	},
	methods: {
		handleSaveItem() {
			this.$refs.ItemFormComponent.validateForm();
		},

		handleSubmitForm(data) {
			const payload = { data: data };

			if (data.mainFile || data.file || (data.files && data.files.length)) {
				payload.withFile = true;
			}
			this.save_item(payload).then((/* answer */) => {
				// console.log(answer);
				// const { data, updateRoute } = answer;
				this.changeRoute({ history: true, steps: -1 });

				/*if (updateRoute) {
					// console.log(data)
					if (data && data.data.id) {
						this.changeRoute({ parent: true });
					}
				}*/
			});
		}
	}
};

export default itemPageMixin;
